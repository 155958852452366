




























































































































































































































































section {
  main {
    width: 1200px;
    margin: 0 auto;

    > div {
      &:nth-child(1) {
        padding: 80px 0;

        > h3 {
          font-size: 30px;
          font-weight: normal;
          color: #000;
          line-height: 40px;
          margin-bottom: 40px;
          margin-left: 30px;
        }

        > div {
          display: flex;

          > div {
            flex: 1;

            &:nth-child(1) {
              margin-left: 30px;
            }

            > span {
              display: block;
              font-size: 14px;
              line-height: 22px;
              color: #333;
            }
          }
        }
      }

      &:nth-child(2) {
        text-align: center;

        > h3 {
          font-size: 18px;
          font-weight: normal;
          color: #000;
          margin: 30px 0;
        }

        > form {
          > div {
            ::v-deep > label {
              font-size: 15px;
              color: #333;
              position: relative;
              right: 15px;
            }
          }

          .hint {
            font-size: 12px;
            color: #737373;
            font-style: italic;
            display: block;
            text-align: right;
            position: relative;
            top: -20px;
          }

          ::v-deep .el-upload-list {
            display: none;
          }

          ::v-deep label[for="banner"] {
            line-height: 30px;
          }
        }

        > div {
          padding-bottom: 50px;

          > button {
            margin: 0 30px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px){
  section {
    main{
      width: 100% !important;
      >div:nth-child(1){
        padding: 20px 0;
        >div{
          display: block;
          >div:nth-child(2){
            margin-left: 30px;
          }
        }
      }
      >div:nth-child(2){
        padding: 0 15px;
      }
    }
  }
}
